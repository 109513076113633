<template>
  <div>
    <header class="search-top">
      <aside>
        <el-select v-model="params.children_category_id" placeholder="请选择一级分类" filterable @change="clear">
          <el-option v-for="item in firstList" :key="item.category_platform_product_id" :value="item.category_platform_product_id" :label="item.name"></el-option>
        </el-select>
        <el-button type="primary" @click="getList">查询</el-button>
      </aside>
      <el-button v-if="flag" type="primary" @click="edit">添加分类</el-button>
    </header>
    <DragTable :header="header" :list-query="list" :sort="true" @change="handle">
      <template #image="{row}"><img class="site-img" :src="row.image" /></template>
      <template #updateTime="{row}">{{row.updated_at && $dayjs(row.updated_at*1000).format('YYYY-MM-DD HH:mm:ss') || ''}}</template>
      <template #status="{row}">{{row.status === 0?'停用':'启用'}}</template>
      <template #control="{row}">
        <div class="control">
          <span @click="edit(row)">编辑</span>
          <span class="word-color red" @click="ctl(row,'del')">删除</span>
          <span v-if="row.status == 1" @click="ctl(row,'stop')">停用</span>
          <span v-else @click="ctl(row,'play')">启用</span>
        </div>
      </template>
    </DragTable>
    <create-second :detail="categoryDetail" v-model="show"></create-second>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from '@vue/composition-api'
import createSecond from './create_second.vue'

export default defineComponent({
  components: { createSecond },
  setup(props,{ root }) {
    const flag = ref(false)
    const categoryDetail = ref({})
    const show = ref(false)
    const params = reactive({
      is_show_page: 0,
      children_category_id: '',
      level: 2
    })
    const header = [
      { name: "序号", value: "eq" },
      { name: "分类ID", value: "id" },
      { name: "一级分类名称", value: "one_name" },
      { name: "二级分类名称", value: "two_name" },
      { name: "导航展示", value: "status", slot: 'status' },
      { name: "更新时间", value: "updated_at", slot: 'updateTime' },
      { name: "操作人", value: "admin_name" },
      { name: "操作", slot: 'control' },
    ]
    const list = reactive({data: []})
    const firstList = ref([])
    watch(show,val=>{
      if(!val) {
        getList()
      }
    })
    const getList = () => {
      if(!params.children_category_id){
        root.$message.warning('请选择分类')
        return
      }
      root.$axios.get('/category/platform/product', {
        params
      })
      .then(res=>{
        res.data.result.forEach((item,i)=>{
          item.eq=i+1
        })
        flag.value = true
        list.data = res.data.result
      })
    }
    const getCategory = (num) => {
      list.data = []
      flag.value = false
      if(!num && num !== 0) return
      root.$axios.get(`/category/platform/screenList`,{
        params: {
          p_id: num
        }
      })
      .then(res=>{
        params.children_category_id = ''
        firstList.value = res.data
      })
    }
    getCategory(0)
    const clear = () => {
      list.data=[]
      flag.value = false
    }
    const edit = data => {
      if(data.id) {
        root.$axios.get(`/category/platform/show/${data.id}`)
        .then(res=>{
          categoryDetail.value = Object.assign(res.data,{
            one_name: data.one_name,
            two_name: data.two_name,
            three_name: data.three_name
          })
          show.value = true
        })
      } else {
        Object.keys(categoryDetail.value).forEach(key=>{
          categoryDetail.value[key] = ''
        })
        categoryDetail.value.one_id= params.children_category_id
        show.value = true
      }
    }
    const ctl = async (data,type)=>{
      let str = ''
      let url = '/category/platform/statusChange'
      let status = null
      switch(type){
        case 'del': str = '确定要删除吗？'
        url = '/category/platform/delete'
        break;
        case 'stop': str = '确定要停用吗？'
        status = 0
        break;
        case 'play': str = '确定要启用吗？'
        status = 1
        break;
      }
      const confirm = await root.$goDialog(str)
      if(!confirm) return
      root.$axios.post(url,{
        status,
        id: data.id
      })
      .then(res=>{
        root.$message.success(res.msg)
        getList()
      })
    }
    const handle = async (data) => {
      try {
        const arr = []
        data.forEach((item,i) => {
          arr.push({ id: item.id, sort: i+1 })
        });
        await root.$axios.post('/category/platform/sort',{
          sort_list: arr
        })
      } catch (error) {
        getList()
        root.$message.error(error)
      }
    }
    return {
      flag,
      params,
      header,
      list,
      firstList,
      categoryDetail,
      show,
      clear,
      getList,
      getCategory,
      edit,
      ctl,
      handle
    }
  },
})
</script>
<style lang="scss" scoped>
.search-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  aside{
    &>div{
      margin-right: 15px;
    }
  }
}
.control {
  display: flex;
  span {
    margin-right: 14px;
    cursor: pointer;
    font-size: 14px;
    color: $mainColor;
    &:last-of-type(1) {
      margin-right: 0;
    }
  }
}
.site-img{
  width: 100%;
  max-height: 120px;
  object-fit: contain;
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>