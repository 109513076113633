<template>
  <div>
    <div class="header">
      <div></div>
      <el-button class="right-btn" type="primary" @click="edit(null)">添加分类</el-button>
    </div>
    <DragTable :header="header" :list-query="list" :sort="true" @change="handle">
      <template #image="{row}"><img class="site-img" :src="row.image" /></template>
      <template #updateTime="{row}">{{row.updated_at && $dayjs(row.updated_at*1000).format('YYYY-MM-DD HH:mm:ss') || ''}}</template>
      <template #status="{row}">{{row.status === 0?'停用':'启用'}}</template>
      <template #control="{row}">
        <div class="control">
          <span @click="edit(row)">编辑</span>
          <span class="word-color red" @click="ctl(row,'del')">删除</span>
          <span v-if="row.status == 1" @click="ctl(row,'stop')">停用</span>
          <span v-else @click="ctl(row,'play')">启用</span>
        </div>
      </template>
    </DragTable>
    <!-- <footer class="table-footer">
      <p></p>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.per_page"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </footer> -->
    <create-first :detail="categoryDetail" v-model="show"></create-first>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from '@vue/composition-api'
import createFirst from './create_first.vue'

export default defineComponent({
  components: { createFirst },
  setup(props,{ root }) {
    const total = ref(10)
    const categoryDetail = ref({})
    const show = ref(false)
    const params = reactive({
      is_show_page: 0,
      children_category_id: '',
      level: 1
    })
    const header = [
      { name: "序号", value: "eq" },
      { name: "分类ID", value: "id" },
      { name: "一级分类名称", value: "one_name" },
      { name: "导航展示", value: "status", slot: 'status' },
      { name: "专题图片", value: "image", slot: 'image' },
      { name: "更新时间", value: "updated_at", slot: 'updateTime' },
      { name: "操作人", value: "admin_name" },
      { name: "操作", slot: 'control', width: 140 },
    ]
    const list = reactive({data: []})
    watch(show,val=>{
      if(!val) {
        getList()
      }
    })
    const getList = () => {
      root.$axios.get('/category/platform/product', {
        params
      })
      .then(res=>{
        res.data.result.forEach((item,i)=>{
          item.eq=i+1
        })
        list.data = res.data.result
        total.value = res.data.pagination && res.data.pagination.total || 0
      })
    }
    getList()
    const edit = data => {
      categoryDetail.value = data || {}
      show.value = true
    }
    const ctl = async (data,type)=>{
      let str = ''
      let url = '/category/platform/statusChange'
      let status = null
      switch(type){
        case 'del': str = '确定要删除吗？'
        url = '/category/platform/delete'
        break;
        case 'stop': str = '确定要停用吗？'
        status = 0
        break;
        case 'play': str = '确定要启用吗？'
        status = 1
        break;
      }
      const confirm = await root.$goDialog(str)
      if(!confirm) return
      root.$axios.post(url,{
        status,
        id: data.id
      })
      .then(res=>{
        root.$message.success(res.msg)
        getList()
      })
    }
    const handle = async (data) => {
      try {
        const arr = []
        data.forEach((item,i) => {
          arr.push({ id: item.id, sort: i+1 })
        });
        await root.$axios.post('/category/platform/sort',{
          sort_list: arr
        })
      } catch (error) {
        getList()
        root.$message.error(error)
      }
    }
    const handleCurrentChange = e => {
      params.page = e
      getList()
    }
    const handleSizeChange = e => {
      params.per_page = e
      params.page = 1
      getList()
    }
    return {
      total,
      params,
      header,
      list,
      categoryDetail,
      show,
      edit,
      ctl,
      handleSizeChange,
      handleCurrentChange,
      handle,
      getList
    }
  },
})
</script>
<style lang="scss" scoped>
.header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.control {
  display: flex;
  span {
    margin-right: 14px;
    cursor: pointer;
    font-size: 14px;
    color: $mainColor;
    &:last-of-type(1) {
      margin-right: 0;
    }
  }
}
.site-img{
  width: 100%;
  max-height: 120px;
  object-fit: contain;
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>