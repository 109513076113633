<template>
  <div>
    <header class="header">
      <el-tabs v-model="active">
        <el-tab-pane label="一级分类" name="1">
        </el-tab-pane>
        <el-tab-pane label="二级分类" name="2">
        </el-tab-pane>
        <el-tab-pane label="三级分类" name="3">
        </el-tab-pane>
      </el-tabs>
      <div>
        <div class="grant-input">
          <div>导入</div>
          <input ref="file" type="file" @change="getFile" />
        </div>
        <el-button type="primary" @click="exportFile">导出</el-button>
      </div>
    </header>
    <first ref="demo1" v-if="active == 1"></first>
    <second ref="demo2" v-if="active == 2"></second>
    <third ref="demo3" v-if="active == 3"></third>
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import first from './components/first.vue'
import Second from './components/second.vue'
import Third from './components/third.vue'

export default defineComponent({
  components: { first, Second, Third },
  setup(props,{ root, refs }) {
    const active = ref('1')
    const act = ref('1')
    const getFile = () => {
      const file = refs.file.files[0];
      const config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      const formData = new FormData()
      formData.append('excel_file', file)
      root.$axios.post('/category/platform/import',formData,config)
      .then(res=>{
        root.$message.success(res.msg)
        if(refs.demo1) {
          refs.demo1.getList()
        }
        if(refs.demo2) {
          refs.demo2.getList()
        }
        if(refs.demo3) {
          refs.demo3.getList()
        }
      })
    };
    const exportFile = () => {
      root.$axios.get(`/category/platform/export`)
        .then(res=>{
          const a = window.document.createElement('a')
          a.href= root.$store.state.CMS.url+res.data.file_path
          a.click()
        })
    }
    return {
      active,
      getFile,
      exportFile
    }
  },
})
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__header{
  margin-bottom: 0;
}
::v-deep .el-tabs__nav-wrap::after{
  bottom: -1px;
}
.header{
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #E4E7ED;
  margin-bottom: 15px;
}
.grant-input {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 84px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #7a7c81;
  line-height: 32px;
  margin-right: 15px;
  background-color: $mainColor;
  div {
    font-size: 14px;
    color: #fff;
  }
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
</style>
