<template>
  <div class="wrap" v-if="visible">
    <div class="create-dialog">
      <h2>{{categoryInfo.id ? '编辑' : '新建'}}分类</h2>
      <div>
        <section class="item">
          <aside>一级分类：</aside>
          <el-input
            v-model="categoryInfo.name"
            placeholder="请输入一级分类名称"
          ></el-input>
        </section>
        <section class="item">
          <el-radio
            style="margin-right: 20px"
            v-model="categoryInfo.topic_type"
            :label="2"
            >不展示商品专题</el-radio
          >
          <el-radio v-model="categoryInfo.topic_type" :label="1"
            >展示商品专题</el-radio
          >
        </section>
        <template v-if="categoryInfo.topic_type == 1">
          <section class="item">
            <aside>专题标题：</aside>
            <el-input
              v-model="categoryInfo.topic_name"
              placeholder="请输入专题标题"
            ></el-input>
          </section>
          <section class="item">
            <aside>专题图片：</aside>
            <upload :info="imgInfo" class="upload-img" @getimage="getimage" />
          </section>
          <section class="item">
            <el-radio style="margin-right: 20px" v-model="categoryInfo.search_type" :label="1"
              >分类选择</el-radio
            >
            <el-radio v-model="categoryInfo.search_type" :label="2">文本搜索</el-radio>
          </section>
          <section v-if="categoryInfo.search_type == 1" class="item">
            <aside></aside>
            <category-table
              :info="site_category"
              @change="getCategoryInfo"
            ></category-table>
          </section>
          <template v-else>
            <section class="item">
              <aside>搜索站点：</aside>
              <el-select v-model="categoryInfo.search_site_ids" multiple collapse-tags>
                <el-option
                  v-for="item in siteList"
                  :key="item.site_id"
                  :value="item.site_id"
                  :label="item.proxy_name"
                ></el-option>
              </el-select>
            </section>
            <section class="item">
              <aside>搜索文本：</aside>
              <el-input
                v-model="categoryInfo.search_site_text"
                placeholder="请输入搜索文本"
              ></el-input>
            </section>
          </template>
        </template>
      </div>
      <footer class="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </footer>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";
import Upload from "@/components/common/Upload";
import CategoryTable from "./category-table.vue";

export default defineComponent({
  components: { Upload, CategoryTable },
  model: {
    prop: "show",
    event: "toggle",
  },
  props: {
    detail: { type: Object, default: () => {} },
    show: { default: false },
  },
  setup(props, { root, emit }) {
    const visible = ref(false);
    const imgInfo = reactive({
      url: "",
      width: 750,
      height: 300,
      requestUrl: '/common/uploadImageOss',
      pathType: 'site_category'
    });
    const site_category = reactive({
      site_category_bind_ids: [],
    });
    const categoryInfo = reactive({
      level: 1,
      name: "",
      one_name: "",
      topic_name: "",
      topic_image: "",
      search_type: 1,
      topic_type: 1,
      search_site_ids: undefined,
      search_site_text: "",
      search_category_ids: undefined,
    });
    const siteList = ref([]);
    watch(
      () => props.show,
      (val) => {
        visible.value = val;
        if (val && props.detail.id) {
          Object.assign(categoryInfo, props.detail);
          categoryInfo.level = 1;
          if(categoryInfo.search_type == 2 && typeof categoryInfo.search_site_ids == 'string') {
            categoryInfo.search_site_ids =
              categoryInfo.search_site_ids.split(",");
          }
          categoryInfo.name = props.detail.one_name;
          getDetail(props.detail.id);
        }
        getSite();
      }
    );
    const getimage = (e) => {
      categoryInfo.topic_image = e;
    };
    const getDetail = (id) => {
      root.$axios
        .get(`/category/platform/show/${id}`)
        .then((res) => {
          imgInfo.url = res.data.topic_image;
          const objArr = Object.keys(res.data.text_search)
          if(objArr.length>0) {
            categoryInfo.search_site_text = objArr[0]
            categoryInfo.search_site_ids = res.data.text_search[objArr[0]]
          }
          categoryInfo.search_category_ids = res.data.select_category_ids.join(',')
          site_category.site_category_bind_ids = categoryInfo.search_category_ids.split(',')
          Object.assign(categoryInfo, res.data);
        })
        
    };
    const getSite = () => {
      root.$axios
        .get(`/category/siteList`)
        .then((res) => {
          siteList.value = res.data;
        })
        
    };
    const create = () => {
      if (!categoryInfo.name || !categoryInfo.name.trim()) {
        root.$message.warning("请输入分类名称");
        return;
      }
      if (categoryInfo.topic_type == 1 && !categoryInfo.topic_name) {
        root.$message.warning("请输入专题标题");
        return;
      }
      if (categoryInfo.topic_type == 1 && !categoryInfo.topic_image) {
        root.$message.warning("请选择专题图片");
        return;
      }
      if (
        categoryInfo.topic_type == 1 &&
        categoryInfo.search_type == 2 &&
        !categoryInfo.search_site_ids
      ) {
        root.$message.warning("请勾选站点");
        return;
      }
      if (
        categoryInfo.topic_type == 1 &&
        categoryInfo.search_type == 2 &&
        !categoryInfo.search_site_text
      ) {
        root.$message.warning("请输入搜索文本");
        return;
      }
      let url = "/category/platform/store";
      if (categoryInfo.id) {
        url = "/category/platform/update";
      }
      if (categoryInfo.search_site_ids) {
        categoryInfo.search_site_ids = categoryInfo.search_site_ids.join(",");
      }
      root.$axios
        .post(url, categoryInfo)
        .then((res) => {
          root.$message.success(res.msg);
          cancel();
        })
        
    };
    const getCategoryInfo = (data) => {
      categoryInfo.search_category_ids =
        (data.site_category_bind_ids &&
          data.site_category_bind_ids.join(",")) ||
        undefined;
    };
    const cancel = () => {
      for(let key in categoryInfo) {
        categoryInfo[key] = undefined
      }
      categoryInfo.level = 1
      categoryInfo.search_type = 1,
      categoryInfo.topic_type = 1,
      imgInfo.url = ''
      visible.value = false;
      emit("toggle", false);
    };
    const confirm = () => {
      create();
    };
    return {
      site_category,
      visible,
      imgInfo,
      categoryInfo,
      siteList,
      cancel,
      confirm,
      getimage,
      getCategoryInfo,
    };
  },
});
</script>
<style lang="scss" scoped>
.wrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9;
}
.create-dialog {
  width: 700px;
  max-height: 80vh;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  & > div {
    height: 100%;
    overflow: auto;
    padding-bottom: 20px;
  }
  .footer {
    padding-top: 10px;
    border-top: 1px solid #eee;
  }
}
.item {
  display: flex;
  align-items: center;
  margin-top: 15px;
  aside {
    white-space: nowrap;
  }
  .upload-img {
    height: 200px;
    margin-bottom: 30px;
  }
}
</style>