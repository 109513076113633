<template>
  <div class="wrap" v-if="visible">
    <div class="create-dialog">
      <h2>{{categoryInfo.id?'编辑':'新建'}}分类</h2>
      <div>
        <section class="item">
          <aside>一级分类：</aside>
          <el-select v-model="categoryInfo.one_id" filterable>
            <el-option
              v-for="item in firstList"
              :key="item.category_platform_product_id"
              :value="item.category_platform_product_id"
              :label="item.name"
            ></el-option>
          </el-select>
        </section>
        <section class="item">
          <aside>二级分类：</aside>
          <el-input v-model="categoryInfo.name" placeholder="请输入二级分类名称"></el-input>
        </section>
      </div>
      <footer class="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </footer>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";

export default defineComponent({
  model: {
    prop: "show",
    event: "toggle",
  },
  props: {
    detail: { type: Object, default: () => {} },
    show: { default: false },
  },
  setup(props, { root, emit }) {
    const visible = ref(false);
    const imgInfo = reactive({
      url: '',
      width: 200,
      height: 200,
      requestUrl: '/common/uploadImageOss',
      pathType: 'site_category'
    })
    const categoryInfo = reactive({
      level: 2,
      two_id: '',
      name: '',
      parent_id: '',
      id: '',
      one_id: ''
    });
    const firstList = ref([]);
    watch(
      () => props.show,
      (val) => {
        visible.value = val;
        if(props.detail) {
          Object.assign(categoryInfo,props.detail)
          categoryInfo.level = 2
          categoryInfo.name = categoryInfo.two_name
        }
      }
    );
    const getScreen = (num) => {
      if (!num && num != 0) return;
      root.$axios
        .get(`/category/platform/screenList`, {
          params: {
            p_id: num,
          },
        })
        .then((res) => {
          firstList.value = res.data
        })
        
    };
    getScreen(0);
    const create = () => {
      categoryInfo.parent_id = categoryInfo.one_id
      if (!categoryInfo.name || !categoryInfo.name.trim()) {
        root.$message.warning("请输入分类名称");
        return;
      }
      let url = `/category/platform/store`
      if(categoryInfo.id) {
        url = `/category/platform/update`
      }
      root.$axios
        .post(url, categoryInfo)
        .then((res) => {
          root.$message.success(res.msg);
          cancel();
        })
        
    };
    const cancel = () => {
      for(let key in categoryInfo) {
        categoryInfo[key] = ''
      }
      imgInfo.url = ''
      emit("toggle", false);
    };
    const confirm = () => {
      create();
    };
    return {
      visible,
      categoryInfo,
      imgInfo,
      firstList,
      cancel,
      confirm,
      getScreen,
    };
  },
});
</script>
<style lang="scss" scoped>
.wrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9;
}
.create-dialog {
  width: 500px;
  max-height: 80vh;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  & > div {
    height: 100%;
    overflow: auto;
    padding-bottom: 20px;
  }
  .footer {
    padding-top: 10px;
    border-top: 1px solid #eee;
  }
}
.item {
  display: flex;
  align-items: center;
  margin-top: 15px;
  aside {
    white-space: nowrap;
  }
}
.upload-img{
  height: 150px;
  margin-top: 15px;
}
</style>