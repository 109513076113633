<template>
  <table class="category-table">
    <tr>
      <td>站点</td>
      <td>一级分类</td>
      <td>二级分类</td>
      <td>三级分类</td>
    </tr>
    <tr v-for="(item, i) in siteList" :key="item.site_id">
      <td>
        <span class="word-color" :class="{'red': clickObj.act1 == item.site_id}" @click="getList(item)">{{
          item.proxy_name
        }}</span>
      </td>
      <td>
        <p v-for="first in item.firstList" :key="first.id + 'f-' + i">
          <el-checkbox
            v-model="first.is_select"
            :disabled="params.category_platform_id && first.is_operate == 0"
            @change="set(first)"
          ></el-checkbox>
          <span
            class="word-color"
            :class="{
              grey: params.category_platform_id && first.is_operate == 0,
              red: clickObj.act2 == first.id,
            }"
            @click="getList(item, first, '2')"
            >{{ first.name }}</span
          >
        </p>
      </td>
      <td>
        <p v-for="second in item.secondList" :key="second.id + 's-' + i">
          <el-checkbox
            v-model="second.is_select"
            :disabled="params.category_platform_id && second.is_operate == 0"
            @change="set(second)"
          ></el-checkbox>
          <span
            class="word-color"
            :class="{
              grey: params.category_platform_id && second.is_operate == 0,
              red: clickObj.act3 == second.id,
            }"
            @click="getList(item, second, '3')"
            >{{ second.name }}</span
          >
        </p>
      </td>
      <td>
        <p v-for="third in item.thirdList" :key="'t-' + i + third.id">
          <el-checkbox
            v-model="third.is_select"
            :disabled="params.category_platform_id && third.is_operate == 0"
            @change="set(third)"
          ></el-checkbox>
          <span
            class="word-color"
            :class="{
              grey: params.category_platform_id && third.is_operate == 0,
            }"
            >{{ third.name }}</span
          >
        </p>
      </td>
    </tr>
  </table>
</template>
<script>
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";

export default defineComponent({
  props: {
    info: { type: Object, default: () => {} },
  },
  setup(props, { root, emit }) {
    const params = reactive({
      category_platform_id: undefined,
      site_category_cancel_ids: [],
      site_category_bind_ids: [],
    });
    const siteList = ref([]);
    const stieTree = reactive([]);
    const clickObj = reactive({
      act1: undefined,
      act2: undefined,
      act3: undefined,
    });
    watch(
      () => props.info,
      (data) => {
        Object.assign(params, data);
      },
      { deep: true }
    );
    const getSite = () => {
      stieTree.value = [];
      Object.assign(params, props.info);
      root.$axios
        .get(`/category/siteList`)
        .then((res) => {
          res.data.forEach((item) => {
            item.firstList = [];
            item.secondList = [];
            item.thirdList = [];
            item.children = [];
            stieTree.push(item);
          });
          siteList.value = res.data;
        })
        
    };
    getSite();
    const getList = (data, data2, type) => {
      // if (data2 && params.category_platform_id && data2.is_operate == 0) return;
      root.$axios
        .get(`/category/site/category`, {
          params: {
            p_id: (data2 && data2.id) || 0,
            site_id: data.site_id || 0,
            platform_category_id: params.category_platform_id,
          },
        })
        .then((res) => {
          res.data.forEach((item) => {
            if (data2 && data2.is_select) {
              item.is_select = true;
            } else {
              item.is_select = trigger(item);
            }
            item.children = [];
          });
          if (type == "2") {
            data.secondList = judge(res.data);
            data.thirdList = []
            clickObj.act2 = data2.id;
            data2.children = res.data;
          } else if (type == "3") {
            data.thirdList = judge(res.data);
            clickObj.act3 = data2.id;
            data2.children = res.data;
          } else {
            data.firstList = judge(res.data);
            clickObj.act1 = data.site_id;
            stieTree.forEach((item) => {
              if (item.site_id == data.site_id) {
                item.children = res.data;
              }
            });
          }
        })
        
    };
    const judge = (list) => { // 列表选中绑定判断，只判断漏选的
      list.forEach((item) => {
        if (
          item.is_select &&
          !params.site_category_bind_ids.includes(item.id)
        ) {
          params.site_category_bind_ids.push(item.id);
        }
      });
      return list;
    };
    const pushIds = (data, parentId) => { // 对单个选项判断是否选中
      const bindList = params.site_category_bind_ids;
      const cancelList = params.site_category_cancel_ids;
      if (data.is_select && !bindList.includes(data.id) && !parentId) {
        params.site_category_bind_ids.push(data.id);
      }
      if (!data.is_select && !cancelList.includes(data.id)) {
        params.site_category_cancel_ids.push(data.id);
      }
      if (data.is_select && cancelList.includes(data.id)) {
        params.site_category_cancel_ids.splice(cancelList.indexOf(data.id), 1);
      }
      if (!data.is_select && bindList.includes(data.id)) {
        params.site_category_bind_ids.splice(bindList.indexOf(data.id), 1);
      }
      if(parentId && data.is_select && bindList.includes(data.id)) {
        params.site_category_bind_ids.splice(bindList.indexOf(data.id), 1);
      }
    }
    const set = async (data) => {
      await pushIds(data)
      if(data.level < 3) {
        await setTrue(data, data.is_select);
      }
      if(data.level > 1) {
        const list = check(stieTree,data)
        dis(data, list)
      }
      emit("change", params);
    };
    const setTrue = (data, flag, level) => { // 对当前选中项下面所有子类做上勾选状态，但不存子类id值
      if (!data.children || data.level == level) return;
      data.children.forEach((item) => {
        item.is_select = flag;
        if(item.is_operate == 1) {
          pushIds(item, data.id)
        }
        if (item.children) {
          setTrue(item, flag);
        }
      });
    };
    const trigger = (data) => {
      const flag = params.site_category_bind_ids.includes(data.id)
      return flag;
    };
    const check = (arr, data) => {
      // 子级查找父级
      let list = [];
      if (arr.length == 0 || data.level == 1) {
        if (data.id) {
          list.unshift(data);
        }
        return list;
      }
      let rev = (childrenArr, id) => {
        for (let i = 0; i < childrenArr.length; i++) {
          let node = childrenArr[i];
          if (node.id == id) {
            list.unshift(node);
            rev(arr, node.p_id);
            break;
          } else if (node.children) {
            rev(node.children, id);
          }
        }
        return list;
      };
      list = rev(arr, data.id);
      return list;
    };
    const dis = (data, list) => { // 子类改变时，取消父类选中，并删除父类id的存值
      const num = list.length -2
      let flag = true
      list[num].children.forEach(item=>{
        if(!item.is_select) {
          flag = false
        }
        if(item.is_operate == 1) {
          pushIds(item)
        }
      })
      // if(flag) { // 如果子类全选中，则只存父id的值,子类id全取消
      //   list[num].children.forEach(item=>{
      //     pushIds(item,list[num].id)
      //   })
      // }
      list[num].is_select = flag
      pushIds(list[num])
    }
    return {
      params,
      siteList,
      clickObj,
      getList,
      set,
    };
  },
});
</script>

<style lang="scss" scoped>
.category-table {
  width: 100%;
  border-collapse: collapse;
  &,
  tr,
  td {
    border: 1px solid rgb(238, 234, 234);
    text-align: left;
    padding: 5px;
    overflow: auto;
    p {
      border-bottom: 1px solid #eee;
    }
  }
  td{
    padding-bottom: 0;
  }
}
</style>