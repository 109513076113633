<template>
  <div class="wrap" v-if="visible">
    <div class="create-dialog">
      <h2>{{categoryInfo.id?'编辑':'新建'}}分类</h2>
      <div>
        <section class="item">
          <aside>一级分类：</aside>
          <el-select v-model="categoryInfo.one_id" filterable @change="getScreen(categoryInfo.one_id,2)">
            <el-option
              v-for="item in firstList"
              :key="item.category_platform_product_id"
              :value="item.category_platform_product_id"
              :label="item.name"
            ></el-option>
          </el-select>
        </section>
        <section class="item">
          <aside>二级分类：</aside>
          <el-select v-model="categoryInfo.two_id" filterable>
            <el-option
              v-for="item in secondList"
              :key="item.category_platform_product_id"
              :value="item.category_platform_product_id"
              :label="item.name"
            ></el-option>
          </el-select>
        </section>
        <section class="item">
          <aside>三级分类：</aside>
          <el-input v-model="categoryInfo.name" placeholder="请输入三级分类名称"></el-input>
        </section>
        <upload :info="imgInfo" class="upload-img" @getimage="getimage" />
      </div>
      <footer class="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </footer>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";
import Upload from "@/components/common/Upload";

export default defineComponent({
  components: { Upload },
  model: {
    prop: "show",
    event: "toggle",
  },
  props: {
    detail: { type: Object, default: () => {} },
    show: { default: false },
  },
  setup(props, { root, emit }) {
    const visible = ref(false);
    const imgInfo = reactive({
      url: '',
      width: 200,
      height: 200,
      requestUrl: '/common/uploadImageOss',
      pathType: 'site_category'
    })
    const categoryInfo = reactive({
      level: 3,
      one_id: '',
      two_id: '',
      name: '',
      image: '',
      parent_id: '',
      id: ''
    });
    const firstList = ref([]);
    const secondList = ref([]);
    watch(
      () => props.show,
      (val) => {
        visible.value = val;
        if(props.detail) {
          Object.assign(categoryInfo,props.detail)
          categoryInfo.level = 3
          categoryInfo.name = categoryInfo.three_name
          categoryInfo.image = categoryInfo.topic_image
          imgInfo.url = categoryInfo.image
          getScreen(props.detail.one_id,1)
        }
      }
    );
    const getimage = (e) => {
      categoryInfo.image = e
    };
    const getScreen = (num,type) => {
      if (!num && num != 0) return;
      root.$axios
        .get(`/category/platform/screenList`, {
          params: {
            p_id: num,
          },
        })
        .then((res) => {
          if(type == 0) {
            firstList.value = res.data
          } else if(type == 2) {
            categoryInfo.two_id = ''
            categoryInfo.parent_id = ''
            secondList.value = res.data
          } else {
            secondList.value = res.data
          }
        })
        
    };
    getScreen(0,0);
    const create = () => {
      categoryInfo.parent_id = categoryInfo.two_id
      if (!categoryInfo.name || !categoryInfo.name.trim()) {
        root.$message.warning("请输入分类名称");
        return;
      }
      if (!categoryInfo.image) {
        root.$message.warning("请上传图片");
        return;
      }
      let url = `/category/platform/store`
      if(categoryInfo.id) {
        url = `/category/platform/update`
      }
      root.$axios
        .post(url, categoryInfo)
        .then((res) => {
          root.$message.success(res.msg);
          cancel();
        })
        
    };
    const cancel = () => {
      for(let key in categoryInfo) {
        categoryInfo[key] = ''
      }
      imgInfo.url = ''
      emit("toggle", false);
    };
    const confirm = () => {
      create();
    };
    return {
      visible,
      categoryInfo,
      imgInfo,
      firstList,
      secondList,
      cancel,
      confirm,
      getimage,
      getScreen,
    };
  },
});
</script>
<style lang="scss" scoped>
.wrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9;
}
.create-dialog {
  width: 500px;
  max-height: 80vh;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  & > div {
    height: 100%;
    overflow: auto;
    padding-bottom: 20px;
  }
  .footer {
    padding-top: 10px;
    border-top: 1px solid #eee;
  }
}
.item {
  display: flex;
  align-items: center;
  margin-top: 15px;
  aside {
    white-space: nowrap;
  }
}
.upload-img{
  height: 150px;
  margin-top: 15px;
}
</style>