var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"category-table"},[_vm._m(0),_vm._l((_vm.siteList),function(item,i){return _c('tr',{key:item.site_id},[_c('td',[_c('span',{staticClass:"word-color",class:{'red': _vm.clickObj.act1 == item.site_id},on:{"click":function($event){return _vm.getList(item)}}},[_vm._v(_vm._s(item.proxy_name))])]),_c('td',_vm._l((item.firstList),function(first){return _c('p',{key:first.id + 'f-' + i},[_c('el-checkbox',{attrs:{"disabled":_vm.params.category_platform_id && first.is_operate == 0},on:{"change":function($event){return _vm.set(first)}},model:{value:(first.is_select),callback:function ($$v) {_vm.$set(first, "is_select", $$v)},expression:"first.is_select"}}),_c('span',{staticClass:"word-color",class:{
            grey: _vm.params.category_platform_id && first.is_operate == 0,
            red: _vm.clickObj.act2 == first.id,
          },on:{"click":function($event){return _vm.getList(item, first, '2')}}},[_vm._v(_vm._s(first.name))])],1)}),0),_c('td',_vm._l((item.secondList),function(second){return _c('p',{key:second.id + 's-' + i},[_c('el-checkbox',{attrs:{"disabled":_vm.params.category_platform_id && second.is_operate == 0},on:{"change":function($event){return _vm.set(second)}},model:{value:(second.is_select),callback:function ($$v) {_vm.$set(second, "is_select", $$v)},expression:"second.is_select"}}),_c('span',{staticClass:"word-color",class:{
            grey: _vm.params.category_platform_id && second.is_operate == 0,
            red: _vm.clickObj.act3 == second.id,
          },on:{"click":function($event){return _vm.getList(item, second, '3')}}},[_vm._v(_vm._s(second.name))])],1)}),0),_c('td',_vm._l((item.thirdList),function(third){return _c('p',{key:'t-' + i + third.id},[_c('el-checkbox',{attrs:{"disabled":_vm.params.category_platform_id && third.is_operate == 0},on:{"change":function($event){return _vm.set(third)}},model:{value:(third.is_select),callback:function ($$v) {_vm.$set(third, "is_select", $$v)},expression:"third.is_select"}}),_c('span',{staticClass:"word-color",class:{
            grey: _vm.params.category_platform_id && third.is_operate == 0,
          }},[_vm._v(_vm._s(third.name))])],1)}),0)])})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',[_vm._v("站点")]),_c('td',[_vm._v("一级分类")]),_c('td',[_vm._v("二级分类")]),_c('td',[_vm._v("三级分类")])])}]

export { render, staticRenderFns }